#root {
  min-height: 100vh;
  position: relative;
}

#top-menu {
  height: 50px;
  background-color: #323a44;
}

#top-menu-title {
  color: white;
  font-weight: bold;
  font-size: larger;
  margin: 10px 20px;
  float: left;
}

#top-menu-email {
  color: white;
  font-size: large;
  margin: 10px 20px;
  float: right;
}

#top-menu-logout {
  color: white;
  font-size: large;
  margin: 10px 20px;
  float: right;
  user-select: none;
}

#top-menu-logout:hover {
  cursor: pointer;
  color: #1ddfd9;
}

#side-menu {
  width: 250px;
  background-color: #ebebeb;
  float: left;
  position: absolute;
  top: 50px;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 0px 10px 0px;
}

#page-content {
  margin-left: 250px;
}

.side-menu-button {
  height: 40px;
  background-color: #dfdfdf;
  margin: 10px;
  text-align: center;
  border: 1px solid #555;
  padding-bottom: 3px;
}

.side-menu-button-current {
  font-weight: bold;
  color: #444;
}

.side-menu-button:hover {
  cursor: pointer;
  color: #006adf;
  border-color: #006adf;
}

.side-menu-button div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.side-menu-button span {
  user-select: none;
}

.aurecas-link {
  font-size: larger;
  color: #006adf;
}

@media (prefers-reduced-motion: no-preference) {
  .loading-icon {
    animation: loading-spin infinite 20s linear;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
