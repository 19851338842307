.feedback-item {
    text-align: left;
    margin: 10px 20px;
    border-bottom: #CCC solid 1px;
}

.feedback-user {
    margin: 5px 10px;
}

.feedback-messages {
    margin: 0 0 10px 10px;
}

.feedback-message {
}

.feedback-message-owner {
    font-weight: bold;
    font-size: small;
    font-style: italic;
    margin: 0 0 0 5px;
}

.feedback-message-date {
    font-size: small;
    font-style: italic;
}

.feedback-message-delete {
    font-size: small;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
}

.feedback-message-text {
    margin: 5px 10px;
    display: block;
}

.feedback-message-original {
    margin: 5px 10px;
    display: block;
    font-style: italic;
}

.feedback-message-holder {
    position: relative;
    margin: 0 10px;
}

.feedback-message-holder.support-message {
    color: #006adf;
}

.feedback-message-holder.deleted-message {
    text-decoration: line-through;
}

.feedback-message-line {
    position: absolute;
    float: left;
    height: 100%;
    border-left: #CCC solid 1px;
}

.feedback-answer {
    margin: 0 15px 10px 0;
    max-width: 720px;
}

.feedback-answer textarea {
    width: 100%;
    height: 68px;
    resize: vertical;
    margin: 10px 0 0 0;
    font-size: larger;
    border-radius: 8px;
    padding: 8px;
}

.feedback-answer button {
    background: #46ab45;
    border: 6px solid transparent;
    border-radius: 8px;
    color: #fff;
    min-height: 40px;
    min-width: 60px;
    font-weight: bold;
}

.feedback-answer button:nth-child(1) {
    background: #1a73e8;
    margin: 0 5px;
}

form:invalid button {
    display: none;
}

.expand-messages {
    margin: 0 0 0 10px;
    width: fit-content;
}

.expand-messages:hover {
    margin: 0 0 0 10px;
    cursor: pointer;
}

.expand-messages span {
    font-weight: bold;
    font-size: smaller;
    user-select: none;
}

.feedback-read-check {
    font-weight: bold;
    color: green;
    float: right;
    position: relative;
    top: -20px;
}

.feedback-username {
    cursor: pointer;
}